import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { IMember } from '../_types/Member';
import { IMemberAddress } from '../_types/MemberAddress';
import { IMemberEmail } from '../_types/MemberEmail';
import { IMemberContactNumber } from '../_types/MemberContactNumber';
import { IMemberNote } from '../_types/MemberNote';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

    constructor( private http: HttpClient ) {}

    getMembers(page, per_page, sortColumn, sortDirection, filter, branchId, regStatus, status): Observable<IMember[]> {

        console.log('getMembers');
        console.log('page: ' + page);
        console.log('per_page: ' + per_page);
        console.log('sortColumn: ' + sortColumn);
        console.log('sortDirection: ' + sortDirection);
        console.log('filter: ' + filter);
        console.log('branchId: ' + branchId);
        console.log('regStatus: ' + regStatus);
        console.log('status: ' + status);

        let myParams = new HttpParams();
        myParams = myParams.append('page', page.toString());
        myParams = myParams.append('per_page', per_page.toString());
        myParams = myParams.append('sortColumn', sortColumn);
        myParams = myParams.append('sortDirection', sortDirection);
        if (filter || filter !== '') {
            myParams = myParams.append('filter', filter);
        }
        if (branchId || branchId !== '') {
            myParams = myParams.append('branchId', branchId);
        }
        if (regStatus || regStatus !== '') {
            myParams = myParams.append('regstatus', regStatus);
        }
        if (status || status !== '') {
            myParams = myParams.append('status', status);
        }

        return this.http.get<any>('member', { params: myParams })
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getMember(id: String): Observable<IMember> {

        return this.http.get<any>('member/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMember(memberId: String, memberRec: any) {
        return this.http.patch<any>('member/' + memberId, memberRec)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    authoriseMemberCentral(memberObjectId: string) {
        return this.http.get<any>('member/registration/central/' + memberObjectId)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    authoriseMemberBranch(memberObjectId: string) {
        return this.http.get<any>('member/registration/branch/' + memberObjectId)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    revokeMember(memberObjectId: string) {
        return this.http.get<any>('member/registration/revoke/' + memberObjectId)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    registerMember(memberRec: any) {
        return this.http.post<any>('member', memberRec)
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    /*branchApproval(objectId) {
        member/registration/branch/objectId
    }*/

    getMemberNotes(id: string) {
        return this.http.get<any>('membernote/memberid/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMemberNote(note: any) {

        if (note._id) {
            // Update (Patch)
            return this.http.patch<any>('membernote/' + note._id, note)
                .pipe(
                    map(res => res.data),
                    catchError(this.handleError),
                    tap(data => console.log(data))
                );
        } else {
            // Insert (POST)
            return this.http.post<any>('membernote/', note)
                .pipe(
                    map(res => res.data),
                    catchError(this.handleError),
                    tap(data => console.log(data))
                );
        }
    }

    deleteMemberNote(id: string) {
        return this.http.delete<any>('membernote/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getMemberLog(id: String) {
        return this.http.get<any>('log/memberid/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMemberAddress(memberId: string, address: IMemberAddress) {

        if (!address._id) {
            delete address._id;
        }

        return this.http.post<any>('member/address/' + memberId, address)
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    deleteMemberAddress(memberId: string, addressId: string) {

        // {‌{url}‌}/member/address/memberObjectId ?addressId=addressObjectId
        let myParams = new HttpParams();
        myParams = myParams.append('addressId', addressId);

        return this.http.delete<any>('member/address/' + memberId, { params: myParams })
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );

    }

    updateMemberEmail(memberId: string, email: IMemberEmail) {

        if (!email._id) {
            delete email._id;
        }

        return this.http.post<any>('member/email/' + memberId, email)
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    deleteMemberEmail(memberId: string, emailId: string) {

        // {‌{url}‌}/member/address/memberObjectId ?addressId=addressObjectId
        let myParams = new HttpParams();
        myParams = myParams.append('emailId', emailId);

        return this.http.delete<any>('member/email/' + memberId, { params: myParams })
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );

    }

    updateMemberContactNumber(memberId: string, contactNumber: IMemberContactNumber) {

        if (!contactNumber._id) {
            delete contactNumber._id;
        }

        return this.http.post<any>('member/contactnumber/' + memberId, contactNumber)
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    deleteMemberContactNumber(memberId: string, contactnumberId: string) {

        // {‌{url}‌}/member/address/memberObjectId ?addressId=addressObjectId
        let myParams = new HttpParams();
        myParams = myParams.append('contactnumberId', contactnumberId);

        return this.http.delete<any>('member/contactnumber/' + memberId, { params: myParams })
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );

    }

    getMemberTransactions(id: String) {
        return this.http.get<any>('membertransaction/memberId/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMemberTransaction(memberTransaction) {
        return this.http.post<any>('membertransaction', memberTransaction)
            .pipe(
                map(res => res),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    deleteMemberTransaction(id: string) {
        return this.http.delete<any>('membertransaction/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMemberInfo(memberId: String, info: any) {
        return this.http.patch<any>('member/' + memberId, info)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getMemberRates() {
        return this.http.get<any>('memberrate')
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    updateMemberPreferences(memberObjId: string, preference: any) {
        return this.http.post<any>('member/preference/' + memberObjId, preference)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    private handleError(error: Response) {

        console.error(error);
        // return Observable.throw(error.json().error || 'Server error');
        return throwError(error);
    }

}
