import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import {environment} from '../environments/environment';

import { AuthService } from './_services/auth.service';

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private router: Router
              ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log('intercepted request ... ');

// Clone the request to add the new header.
    // let authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json')});

    // console.log('INTERCEPTOR: Incoming url ' + req.url);

    // Prefix the url with the API address
    let userToken = null;

    if (req.url.substring(0, 5) === 'utils') {
      /*console.log('STRIPPING TOKEN!' + req.url.substring(0, 5));*/
      userToken = null;
      req = req.clone({url: environment.API_URL + req.url});

      // req = req.clone({ headers: req.headers.set('Content-Type', 'multipart/form-data')});
    } else {
      /*console.log('LEAVING TOKEN!' + req.url.substring(0, 5));*/
      userToken = this.authService.getToken();
      req = req.clone({url: this.authService.APIUrl + req.url});

      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json')});
    }

    // Append the User Token to the call header or Bearer token if no user token

    if (userToken) {
      console.log('HAVE USER TOKEN - Adding to header');
      req = req.clone({ headers: req.headers.set('x-access-token', userToken)});
    } else {
      // Append the Bearer Token to the Header
      console.log('NO USER TOKEN - Using bearer token');
      req = req.clone({ headers: req.headers.set('Authorization', this.authService.bearerToken.toString())});
    }

    // Add in the content-type


    console.log(req);

    // send the newly created request
    return next.handle(req)
      .catch((error, caught) => {
        // intercept the response error and displace it to the console
        console.log('Error Occurred');
        console.log(error);

        if (error.status === 403) {
          // Redirect to the login for now but need to put in a login dialog here in future
          this.router.navigate(['/pages/login']);

          console.log(this.router.url);
          /*if(this.router.url !== '/signin') {
            /!*this.loginDialog();*!/
            console.log('Redirect to login')
          }*/

        }

        // return the error to the method that called it
        return Observable.throw(error);
      }) as any;
  }


}
