import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {AuthService} from '../_services/auth.service';
import {AuthenticationService} from '../_services/authentication.service';
import {BranchService} from '../_services/branch.service';

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
}, {
    path: '/member',
    title: 'Members',
    type: 'sub',
    icontype: 'people',
    collapse: 'members',
    children: [
        {path: 'list', title: 'View Members', ab: 'VM'},
        {path: 'registration', title: 'Member Registrations', ab: 'MR'}
    ]
}, {
    path: '/branch/list',
    title: 'Branches',
    type: 'link',
    icontype: 'domain'

}, {
    path: '/event',
    title: 'Events',
    type: 'sub',
    icontype: 'event',
    collapse: 'events',
    children: [
        {path: 'list', title: 'Events list', ab: 'EL'},
        {path: 'calendar', title: 'Calendar View', ab: 'EC'}
    ]
}
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public branchName: string;

    constructor(private authService: AuthService,
                private authenticationService: AuthenticationService,
                private branchService: BranchService) {}

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        if (this.authService.getRole() === 'super_admin' || this.authService.getRole() === 'admin') {
            this.menuItems.splice(1, 0, {
                path: '/user/list',
                title: 'User Management',
                type: 'link',
                icontype: 'assignment_ind'
            });
        }

        this.branchName = this.branchService.getBranchName(this.authService.getBranchId());



        /*this.branchService.getBranchByNo(+this.authService.getBranchId())
            .subscribe(result => {
                    this.branchName = result[0].branch;
                },
                error => {
                    this.branchName = '[Error]';
                });*/

    }

    getUsername() {
        return this.authService.getUsername();
    }

    getRole() {
        return this.authService.getRole();
    }

    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout() {
        this.authenticationService.logout();
    }
}
