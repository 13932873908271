import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, tap, catchError, delay} from 'rxjs/operators';
import {IEvent} from '../_types/Event';
import {IMeta} from '../_types/Meta';

@Injectable()
export class EventService {
    constructor(private http: HttpClient) {}

    getEvents(start: string, end: string, isModerated: boolean, isPrivate: boolean, isPending: boolean, page: number, per_page: number): Observable<IEvent[]> {

        console.log('isModerated ' + isModerated);
        console.log('isPrivate ' + isPrivate);
        console.log('isPending' + isPending);

        let Params = new HttpParams();

        if (page != null) {
            Params = Params.append('page', '1');
        }

        if (per_page != null) {
            Params = Params.append('per_page', '100');
        }

        if (start != null) {
            Params = Params.append('startdate', start);
        }

        if (end != null) {
            Params = Params.append('enddate', end);
        }

        if (isModerated != null) {
            Params = Params.append('moderated', isModerated.toString());
        }

        if (isPrivate != null) {
            console.log('hello');
            Params = Params.append('private', isPrivate.toString());
        }

        if (isPending != null) {
            Params = Params.append('pendingpublish', isPending.toString());
        }

        return this.http.get<any>('event', {params: Params})
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getNextEvents(noOfEvents: number): Observable<IEvent[]> {

        const today = new Date();
        today.setTime(Date.now());

        const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        return this.http.get<any>('event/?page=1&per_page=' + noOfEvents + '&live=true&startdate=' + todayStr)
            .pipe(
                map(res => res.data),
                catchError(this.handleError)
            );
    }

    getEvent(id: String): Observable<IEvent> {

        const transformEvent = function(eventItem: IEvent) {

            eventItem.start = new Date(eventItem.start);
            eventItem.end = new Date(eventItem.end);

            return <IEvent>eventItem;
        };

        return this.http.get<any>( 'event/' + id)
            .pipe(
                map( res => <IEvent>transformEvent(res.data)),
                catchError(this.handleError),
                tap(data => console.log(JSON.stringify(data)))
            );
    }

    addEvent(body: Object): Observable<IEvent> {
        const bodyString = JSON.stringify(body);

        return this.http.post<any>('event', body)
            .pipe(
                map(res => res.data),
                catchError((error: any) => throwError(error.json().error || 'Server error'))
            );
    }

    saveEvent(event: IEvent): Observable<IMeta> {
        const eventString = JSON.stringify(event);

        if (event._id === '0') { event._id = null; }

        if (event._id) {
            return this.http.patch<any>('event/' + event._id, eventString)
                .pipe(
                    map(res => res.data),
                    catchError((error: any) => throwError(error.json().error || 'Server error')),
                    tap(data => console.log(JSON.stringify(data)))
                );
        } else {
            return this.http.post<any>('event', eventString)
                .pipe(
                    map(res => res.data),
                    catchError((error: any) => throwError(error.json().error || 'Server error')),
                    tap(data => console.log(JSON.stringify(data)))
                );
        }
    }

    deleteEvent(id: String): Observable<IMeta> {
        console.log('Delete Event: ' + id);

        return this.http.delete<any>('event/' + id)
            .pipe(
                map(res => res.data),
                catchError((error: any) => throwError(error.json().error || 'Server error')),
                tap(data => console.log(JSON.stringify(data)))
            );
    }

    uploadFile(files: any): Observable<any> {
        console.log('Upload Service');

        const formData: FormData = new FormData();
        formData.append('client', 'augb');
        formData.append('target', 'events');
        formData.append('file', files);

        console.log(formData);

        return this.http.post<any>('utils/img/upload', formData)
            .pipe(
                delay(1000),
                catchError((error: any) => throwError(error.error || 'Server error'))
            );
    }

    private handleError(error: Response) {
        console.error(error);
        return throwError(error);
    }

}
