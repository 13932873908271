import {Injectable} from '@angular/core';
import {BranchService} from './branch.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {

    constructor() { }

    // public APIUrl = 'https://api.bitcre8.co.uk/augb/';
    public APIUrl = environment.API_URL;
    private currToken: string;
    private currUsername: string;
    private role: string;
    private branchId: string;
    private branchName: string;
    public bearerToken: String = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyIkX18iOnsic3RyaWN0TW9kZSI6dHJ1ZSwiZ2V0dGVycyI6e30sIndhc1BvcHVsYXRlZCI6ZmFsc2UsImFjdGl2ZVBhdGhzIjp7InBhdGhzIjp7Il9fdiI6ImluaXQiLCJhZG1pbiI6ImluaXQiLCJwYXNzd29yZCI6ImluaXQiLCJuYW1lIjoiaW5pdCIsIl9pZCI6ImluaXQifSwic3RhdGVzIjp7Imlnbm9yZSI6e30sImRlZmF1bHQiOnt9LCJpbml0Ijp7Il9fdiI6dHJ1ZSwiYWRtaW4iOnRydWUsInBhc3N3b3JkIjp0cnVlLCJuYW1lIjp0cnVlLCJfaWQiOnRydWV9LCJtb2RpZnkiOnt9LCJyZXF1aXJlIjp7fX0sInN0YXRlTmFtZXMiOlsicmVxdWlyZSIsIm1vZGlmeSIsImluaXQiLCJkZWZhdWx0IiwiaWdub3JlIl19LCJlbWl0dGVyIjp7ImRvbWFpbiI6bnVsbCwiX2V2ZW50cyI6e30sIl9ldmVudHNDb3VudCI6MCwiX21heExpc3RlbmVycyI6MH19LCJpc05ldyI6ZmFsc2UsIl9kb2MiOnsiX192IjowLCJhZG1pbiI6dHJ1ZSwicGFzc3dvcmQiOiJocnljYWoiLCJuYW1lIjoibWlzaCIsIl9pZCI6IjU3MTllMTljODYwOWI1YTQyYjUwMjgzYyJ9LCJfcHJlcyI6eyIkX19vcmlnaW5hbF9zYXZlIjpbbnVsbCxudWxsXX0sIl9wb3N0cyI6eyIkX19vcmlnaW5hbF9zYXZlIjpbXX0sImlhdCI6MTQ2MjgwMjAzOSwiZXhwIjoxNDYyODAyMzM5fQ.jUtIN0jUPs0eUOy2tdIxbgescN12ft7avMCEro8cDsM';

    processToken(tokenResp, email) {
        this.currToken = tokenResp.data.token;

        localStorage.clear();
        localStorage.setItem('token', this.currToken);

        const tokenPayload = JSON.parse(atob(tokenResp.data.token.split('.')[1].replace('-', '+').replace('_', '/')));

        localStorage.setItem('userName', tokenPayload.firstname + ' ' + tokenPayload.lastname);
        localStorage.setItem('firstName', tokenPayload.firstname);
        localStorage.setItem('lastName', tokenPayload.lastname);
        localStorage.setItem('role', tokenPayload.role);
        localStorage.setItem('userId', tokenPayload._id);
        localStorage.setItem('accountId', tokenPayload.accountId);
        localStorage.setItem('email', email);
        localStorage.setItem('branchId', tokenPayload.branchId);
    }

    getToken() {
        if (!this.currToken) {
            this.currToken = localStorage.getItem('token');
        }
        return this.currToken;
    }

    getUsername() {
        if (!this.currUsername) {
            this.currUsername = localStorage.getItem('userName');
        }
        return this.currUsername;
    }

    getRole() {
        if (!this.role) {
            this.role = localStorage.getItem('role');
        }
        return this.role;
    }

    getBranchId() {
        if (!this.branchId) {
            this.branchId = localStorage.getItem('branchId');
        }
        return this.branchId;
    }

    clearStoredData() {
        this.currToken = null;
        this.currUsername = null;
        this.role = null;

        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('branchId');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('userId');
        localStorage.removeItem('accountId');
        localStorage.removeItem('email');
    }

}
