import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {BranchService} from './branch.service';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,
                private router: Router,
                private auth: AuthService
                ) {}

    private currToken: string;
    public bearerToken: String = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyIkX18iOnsic3RyaWN0TW9kZSI6dHJ1ZSwiZ2V0dGVycyI6e30sIndhc1BvcHVsYXRlZCI6ZmFsc2UsImFjdGl2ZVBhdGhzIjp7InBhdGhzIjp7Il9fdiI6ImluaXQiLCJhZG1pbiI6ImluaXQiLCJwYXNzd29yZCI6ImluaXQiLCJuYW1lIjoiaW5pdCIsIl9pZCI6ImluaXQifSwic3RhdGVzIjp7Imlnbm9yZSI6e30sImRlZmF1bHQiOnt9LCJpbml0Ijp7Il9fdiI6dHJ1ZSwiYWRtaW4iOnRydWUsInBhc3N3b3JkIjp0cnVlLCJuYW1lIjp0cnVlLCJfaWQiOnRydWV9LCJtb2RpZnkiOnt9LCJyZXF1aXJlIjp7fX0sInN0YXRlTmFtZXMiOlsicmVxdWlyZSIsIm1vZGlmeSIsImluaXQiLCJkZWZhdWx0IiwiaWdub3JlIl19LCJlbWl0dGVyIjp7ImRvbWFpbiI6bnVsbCwiX2V2ZW50cyI6e30sIl9ldmVudHNDb3VudCI6MCwiX21heExpc3RlbmVycyI6MH19LCJpc05ldyI6ZmFsc2UsIl9kb2MiOnsiX192IjowLCJhZG1pbiI6dHJ1ZSwicGFzc3dvcmQiOiJocnljYWoiLCJuYW1lIjoibWlzaCIsIl9pZCI6IjU3MTllMTljODYwOWI1YTQyYjUwMjgzYyJ9LCJfcHJlcyI6eyIkX19vcmlnaW5hbF9zYXZlIjpbbnVsbCxudWxsXX0sIl9wb3N0cyI6eyIkX19vcmlnaW5hbF9zYXZlIjpbXX0sImlhdCI6MTQ2MjgwMjAzOSwiZXhwIjoxNDYyODAyMzM5fQ.jUtIN0jUPs0eUOy2tdIxbgescN12ft7avMCEro8cDsM';

    authenticate(email: string, password: string) {
        return this.http.post<any>('authenticate', JSON.stringify({email: email, password: password}))
            .pipe(
                map((response: Response) => response)
            );
    }

    login(email: string, password: string, token: string): Observable<any> {

        this.currToken = '';

        this.auth.clearStoredData();

        return this.http.post<any>('authenticate/complete', JSON.stringify({email: email, password: password, token: token}))
            .pipe(
                map((response: Response) => {
                    // login successful if there's a jwt token in the response
                    const tokenResp = <any>response;
                    if (tokenResp && tokenResp.data.token) {
                        // store user details and jwt token in local storage to keep user logged in

                        /*this.currToken = JSON.stringify(tokenResp.data.token);*/
                        this.currToken = tokenResp.data.token;

                        localStorage.setItem('token', this.currToken);

                        const tokenPayload = JSON.parse(atob(tokenResp.data.token.split('.')[1].replace('-', '+').replace('_', '/')));

                        localStorage.setItem('userName', tokenPayload.firstname + ' ' + tokenPayload.lastname);
                        localStorage.setItem('role', tokenPayload.role);
                        localStorage.setItem('firstName', tokenPayload.firstname);
                        localStorage.setItem('lastName', tokenPayload.lastname);
                        localStorage.setItem('userId', tokenPayload._id);
                        localStorage.setItem('accountId', tokenPayload.accountId);
                        localStorage.setItem('email', email);
                        localStorage.setItem('branchId', tokenPayload.branchId);

                        /*this.branchService.getBranchByNo(tokenPayload.branchId)
                            .subscribe(result => {
                                    localStorage.setItem('branchName', result.branch);
                                },
                                error => {
                                    localStorage.setItem('branchName', '[BranchError]');
                                });*/

                    }
                })
            );
    }

    getToken() {

        if (!this.currToken) {
            this.currToken = localStorage.getItem('token');
        }

        return this.currToken;
    }

    logout() {
        // remove user from local storage
        console.log('LOGGING OUT!!!');
        this.currToken = '';

        this.auth.clearStoredData();

        /* localStorage.removeItem('token');
         localStorage.removeItem('userName');
         localStorage.removeItem('role');*/

        this.router.navigate(['/pages/login']);
    }

    requestPassword(email: String) {

        console.log('users/forgot');

        return this.http.post<any>('user/forgot', JSON.stringify({email: email}))
            .pipe(
                map((response: Response) => response)
            );
    }

    resetPassword(token: String, password: String) {

        console.log('POSTING Password Reset' + token);
        console.log(JSON.stringify({password: password}));

        return this.http.post<any>('user/resetpassword/' + token, JSON.stringify({password: password}))
            .pipe(
                map((response: Response) => response)
            );
    }

}
