import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( private http: HttpClient ) { }

  getUsers() {
    return this.http.get<any>('user')
        .pipe(
            map(res => res.data),
            tap(data => console.log(data))
        );
  }

  registerUser(reg: any) {
    return this.http.post<any>('register', reg)
        .pipe(
            map(res => res.data),
            tap(data => console.log(data))
        );
  }

  registerComplete(token: string, reg: any) {
      return this.http.post<any>('register/complete/' + token, reg)
          .pipe(
              map(res => res.data),
              tap(data => console.log(data))
          );
  }

}
