import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../_services/authentication.service';
import {Observable, throwError} from 'rxjs';
import {map, catchError, tap} from 'rxjs/operators';
import {IBranch} from '../_types/Branch';
import {IEvent} from '../_types/Event';
// import {SelectItem} from 'primeng/primeng';
import {IMeta} from '../_types/Meta';

@Injectable()
export class BranchService {
    constructor(private http: HttpClient,
                private authService: AuthenticationService) {}


    private branchList = [
        {
            '_id': '58e0c1b9389d247909c92aa6',
            'branchId': 19,
            'branch': 'Nottingham'
        },
        {
            '_id': '58e0af81389d247909c92a98',
            'branchId': 5,
            'branch': 'Bradford'
        },
        {
            '_id': '58e0ba1c389d247909c92aa2',
            'branchId': 15,
            'branch': 'Leeds'
        },
        {
            '_id': '58e0c2b3389d247909c92aa8',
            'branchId': 21,
            'branch': 'Rochdale'
        },
        {
            '_id': '58e0affd389d247909c92a99',
            'branchId': 6,
            'branch': 'Bury'
        },
        {
            '_id': '58e0c03b389d247909c92aa4',
            'branchId': 17,
            'branch': 'London'
        },
        {
            '_id': '58e0a433389d247909c92a96',
            'branchId': 3,
            'branch': 'Stockport'
        },
        {
            '_id': '58e0b08d389d247909c92a9a',
            'branchId': 7,
            'branch': 'Coventry'
        },
        {
            '_id': '58e0b94c389d247909c92aa1',
            'branchId': 14,
            'branch': 'Keighley'
        },
        {
            '_id': '58e0bf7e389d247909c92aa3',
            'branchId': 16,
            'branch': 'Leicester'
        },
        {
            '_id': '58e0c3a4389d247909c92aaa',
            'branchId': 23,
            'branch': 'Wolverhampton'
        },
        {
            '_id': '58e0b851389d247909c92aa0',
            'branchId': 13,
            'branch': 'Huddersfield'
        },
        {
            '_id': '58e0c13e389d247909c92aa5',
            'branchId': 18,
            'branch': 'Luton'
        },
        {
            '_id': '5ace89dd5f52c43de94029e0',
            'branchId': 24,
            'branch': 'Test Branch'
        },
        {
            '_id': '58e0c34a389d247909c92aa9',
            'branchId': 22,
            'branch': 'Waltham Cross'
        },
        {
            '_id': '58e0b7ea389d247909c92a9f',
            'branchId': 12,
            'branch': 'Halifax'
        },
        {
            '_id': '58e0c22b389d247909c92aa7',
            'branchId': 20,
            'branch': 'Reading'
        },
        {
            '_id': '5a6a4d87b30b253a181dd6d9',
            'branchId': 1,
            'branch': 'Manchester'
        },
        {
            '_id': '58e0b777389d247909c92a9e',
            'branchId': 11,
            'branch': 'Gloucester'
        },
        {
            '_id': '58e0b2b2389d247909c92a9d',
            'branchId': 10,
            'branch': 'Edinburgh'
        },
        {
            '_id': '5849e968205587941793399b',
            'branchId': 2,
            'branch': 'Ashton'
        },
        {
            '_id': '58e0aeb7389d247909c92a97',
            'branchId': 4,
            'branch': 'Bolton'
        },
        {
            '_id': '58e0b18c389d247909c92a9c',
            'branchId': 9,
            'branch': 'Doncaster'
        },
        {
            '_id': '58e0b11e389d247909c92a9b',
            'branchId': 8,
            'branch': 'Derby'
        }
    ];



    getBranchList(): Observable<any> {
        return this.http.get<any>('branch/?view=summary');
    }

    getBranches(): Observable<any> {
        return this.http.get<any>('branch')
            .pipe(
                map(res => res.data),
                catchError(this.handleError)
            );
    }

    getBranch(id: string): Observable<any> {
        return this.http.get<any>('branch/' + id)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getBranchByNo(no: number): Observable<any> {
        return this.http.get<any>('branch/branchid/' + no)
            .pipe(
                map(res => res.data),
                catchError(this.handleError),
                tap(data => console.log(data))
            );
    }

    getBranchEvents(branchId: string): Observable<IEvent[]> {
        return this.http.get<any>('branches/' + branchId + '/event')
            .pipe(
                map(res => res.data),
                catchError(this.handleError)
            );
    }

    saveBranch(branch: IBranch): Observable<any> {
        const branchString = JSON.stringify(branch);

        // Convert a 0 ID to null
        if (branch._id === '0') { branch._id = null; }

        if (branch._id) {
            return this.http.patch('branch/' + branch._id, branchString)
                .pipe(
                    map((res: Response) => res.json()),
                    catchError((error: any) => throwError(error.json().error || 'Server error')),
                    tap(data => console.log(JSON.stringify(data)))
                );
        } else {
            return this.http.post('branch', branchString)
                .pipe(
                    map((res: Response) => res.json()),
                    catchError((error: any) => throwError(error.json().error || 'Server error')),
                    tap(data => console.log(JSON.stringify(data)))
                );
        }
    }



    getBranchName(branchId: string)  {

        /*let retVal = this.branchList.filter(x => x.branchId.toString() === branchId)[0].branch || '--';

        if (!retVal) {
            retVal = '---';
        }

        return retVal;*/

        let retVal = '--';
        if (branchId === '19') {retVal = 'Nottingham'; }
        if (branchId === '5') {retVal = 'Bradford'; }
        if (branchId === '15') {retVal = 'Leeds'; }
        if (branchId === '21') {retVal = 'Rochdale'; }
        if (branchId === '6') {retVal = 'Bury'; }
        if (branchId === '17') {retVal = 'London'; }
        if (branchId === '3') {retVal = 'Stockport'; }
        if (branchId === '7') {retVal = 'Coventry'; }
        if (branchId === '14') {retVal = 'Keighley'; }
        if (branchId === '16') {retVal = 'Leicester'; }
        if (branchId === '23') {retVal = 'Wolverhampton'; }
        if (branchId === '13') {retVal = 'Huddersfield'; }
        if (branchId === '18') {retVal = 'Luton'; }
        if (branchId === '24') {retVal = 'Glasgow'; }
        if (branchId === '22') {retVal = 'Waltham Cross'; }
        if (branchId === '12') {retVal = 'Halifax'; }
        if (branchId === '20') {retVal = 'Reading'; }
        if (branchId === '1') {retVal = 'Manchester'; }
        if (branchId === '11') {retVal = 'Gloucester'; }
        if (branchId === '10') {retVal = 'Edinburgh'; }
        if (branchId === '2') {retVal = 'Ashton'; }
        if (branchId === '4') {retVal = 'Bolton'; }
        if (branchId === '9') {retVal = 'Doncaster'; }
        if (branchId === '8') {retVal = 'Derby'; }

        return retVal;


    }

    private handleError(error: Response) {

        console.error(error);
        return throwError(error);
    }

}
